:root {
  --yin_bg: #FFFFFF;
  --yin_faded_bg: #F4F6F9;
  --yin_text_sharp:#101828;
  --yin_text_faded: #667085;
  --yin_green_bg: #ECFDF3;
  --yin_orange_bg: #FED8B1;
  --yang_bg: #101828;
  --yang_faded_bg: #344054;
  --yang_text_sharp:#F2F4F7;
  --yang_text_normal:#F2F4F7;
  --yang_text_lite: #D0D5DD;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--yang_bg);
  width: 280px;
  height: 100%;
  flex: 0 0 auto;
  overflow-y:hidden;
  overflow-x:hidden;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .sidebar {
    width: 0px;
  }
}

.sidebar-item-icon {
  color: var(--yang_text_lite);
  white-space: nowrap;
}

.sidebar-item-text {
  color: var(--yang_text_normal);
  white-space: nowrap;
}

.sidebar-bubble-text {
  color: var(--yang_text_normal);
}

.sidebar-divider {
  color: var(--yang_faded_bg);
  margin-left: 10;
  margin-right: 1;
}

.container {
  background-color: var(--yang_bg);
  display: flex;
  height: 100%;
}

.content-area {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.header{
  position: sticky;
  top: 0;
  background-color: var(--yin_bg);
  border-top-left-radius: 40px;
  margin-top: 16px;
  padding: 20px 80px 10px;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.hero {
  flex: auto;
  background-color: var(--yin_faded_bg);
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
}

.mapwithmarker{
  height: 500px;
  width: 500px;
  display: block;
}

.login-container {
  display: flex;
  height: 100%;
}

.stat-icon {
  color: var(--yin_text_faded);
  padding: 10px;
}

.biz-info {
  .session-item {
    border-bottom: solid 1px #a9a8a8;
    padding-bottom: 20px;
    padding-top: 10px
  }

  .session-item-attr {
    padding-top: 5px
  }

  .session-item-cat-primary {
    border: solid 1px #9b9a9a;
    border-radius: 5px;
    padding: 0px 10px;
    font-weight: bold
  }

  .contact-social {
    float: 'left';
    margin-right: '10px';
    min-width: '90px';
    font-weight: 'bold'
  }
}

@media only screen and (max-width: 550px) {
  .header {
    margin-top: 0;
    padding: 10px 20px;
    border-radius: unset;
  }
  .hero {
    padding: 10px 20px 20px;
  }
}
