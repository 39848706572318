@font-face {
  font-family: 'Hellix-ExtraBold';
  src: url(/src/fonts/Hellix-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Hellix-Bold';
  src: url(/src/fonts/Hellix-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Hellix-Regular';
  src: url(/src/fonts/Hellix-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Hellix-Medium';
  src: url(/src/fonts/Hellix-Medium.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Hellix-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#pleperScriptTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pleperScriptTable td, #pleperScriptTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#pleperScriptTable tr:nth-child(even){background-color: #f2f2f2;}

#pleperScriptTable tr:hover {background-color: #ddd;}

#pleperScriptTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}